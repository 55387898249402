.tabs {
  &__container {
    display: flex;
  }
  &__item-tab {
    padding: 8px 12px;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
    width: 139px;
    text-align: center;

    &--active {
      color: $colorBase;
      font-weight: 700;
      border-bottom: 3px solid $colorBase;
    }
    &--bordered {
      display: grid;
      gap: 24px;
      // justify-content: center;
      border-bottom: 3px solid $lightGray;
    }
  }
}
.tabs-panel__container {
  display: grid;
  gap: 24px;
}
.tab-panel {
  display: grid;
  gap: 20px;
}

.empty-tab {
  min-height: calc(100vh - 417px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-btn-survey {
  display: flex;
  justify-content: end;
  gap: 12px;
  top: -68px;
  position: relative;
}
