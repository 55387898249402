/* EDITOR MARKDOWN */
.editor-markdown__container {
  margin-bottom: 1.5rem;
  border-radius: 4px 4px 0 0;

  label {
    border-radius: 4px 4px 0 0;
    --text-opacity: 1;
    color: #767676;
    color: rgba(118, 118, 118, var(--text-opacity));
    padding: 0;
    font-size: 0.75rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    padding: 0.6rem;
    margin: 0;
    display: block;
    background-color: rgba(222, 237, 242, 8);
  }
  .rc-md-editor .button {
    padding: 0;
    border-radius: inherit;
    background-color: inherit;
    font-weight: inherit;
    border: 0 none;
  }
}
