/*------------------------------------*\
    $BUTTON
\*------------------------------------*/

.button {
  display: flex;
  padding: 10px 12px;
  min-height: 45px;
  font-weight: bold;
  border-radius: 32px;
  background-color: $colorBase;
  color: white;
  align-items: center;
  justify-content: center;
  border-color: $colorBase;
  border-style: solid;
  text-decoration: none;

  &--flat {
    min-height: 20px;
  }

  cursor: pointer;
  &.disabled {
    z-index: inherit;
    --bg-opacity: 1;
    background-color: #c4c4c4;
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
    display: inline-block;
  }

  &.w-full {
    width: 100%;
  }

  &.secondary {
    border: 1px solid $colorBase;
    background: white;
    color: $colorBase;
  }
}

button[type='button'] {
  &.link {
    color: rgba($colorBase, 0.7);
    padding: 0;
    margin: 0 10px;
    border: 0 none;
    background: none;
    text-decoration: underline;
    outline: none;
  }
}
