/*------------------------------------*\
    $SKELETON
\*------------------------------------*/

.show-loading-animation {
  &.rect-shape,
  &.round-shape,
  &.text-row,
  .rect-shape,
  .round-shape,
  .text-row {
    animation: react-placeholder-pulse 1.5s infinite;
  }
}

@keyframes react-placeholder-pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.skeleton {
  .table-row {
    height: 2.5rem !important;
  }
}
