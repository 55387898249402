.category {
  p {
    margin: 0;
  }

  background-color: #ececec;
  display: inline-block;
  padding: 4px 12px;
  border-radius: 1rem;
  margin-right: 8px;
  color: #444444;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}
.active {
  .category {
    background-color: $colorBase;
    color: #fff;
  }
}
.bg__tooltip {
  background-color: white;

  .title {
    color: $colorBase;
    font-weight: bold;
    font-size: 0.875rem;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: $colorBase;
    padding-bottom: 8px;
  }
  .description {
    font-size: 0.875rem;
    color: #444444;
    padding: 8px 0px;
  }
}
