.custom_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  backdrop-filter: blur(4px);
  .reschedule-session {
    background-color: white;
    padding: 24px;
    border-radius: 4px;
    outline: none;

    .modal__title-container {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #0000001a;

      .title {
        font-size: 16px;
      }

      .close-modal {
        color: #0bb5e7;
        cursor: pointer;
      }
    }
    .description {
      padding: 25px 0px 25px 0px;
    }
    .title__success {
      display: flex;
      align-items: center;
    }
    .content__success {
      border: 1px solid #0000001a;
      border-radius: 20px;
      padding: 25px;
    }
    .reschedule__date {
      display: grid;
      grid-template-columns: repeat(2, 100px);
      align-items: baseline;
      gap: 6rem;
    }
    .error__container {
      .error__text {
        display: flex;
        flex-direction: column;
        padding: 20px;
      }
      .error__user {
        display: flex;
        border: 1px solid #f4f4f4;
        border-radius: 8px;
        background-color: #f4f4f4;
        font-weight: 700;
        gap: 10px;
        margin-top: 30px;
      }
    }

    .confirmation-messagge {
      display: grid;
      gap: 20px;
      .success-icon {
        color: #389e0d;
        justify-self: center;
      }
      .error-icon {
        color: #f5222d;
        justify-self: center;
      }
    }
  }
}
