.container-pagination {
  display: flex;
  flex-direction: column;
  .container-pagination-actions {
    display: flex;
    align-items: center;
    width: 383px;
    column-gap: 16px;
    justify-content: flex-end;
    .pagination {
      display: flex;
      align-items: center;
      gap: 12px;
      .input {
        width: 80px;
        height: 32px;
        border-radius: 100px;
        border: 1px solid #444444;
        text-align: center;
        padding: 0;
        color: rgba(0, 0, 0, 0.87);
      }
      .error {
        border: 1px solid red;
        color: red;
      }
    }
    .pagination-button {
      color: #0bb5e7;
    }
  }
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 23px;
  }
}
