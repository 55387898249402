.dashboard-container {
  padding: 24px;

  .table-header tr {
    background-color: rgba(#cccccc, 0.2);
    th {
      font-weight: bold;
    }
  }
  .table-footer {
    background-color: rgba($colorBase, 0.1);
    th,
    td {
      font-weight: bold;
    }
  }
}
