/*------------------------------------*\
    $LAYOUT
\*------------------------------------*/
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
$radius: 8px;
$hoverTransition: 0.3s;
$animationTime: 0.8s;
$baseSpace: 25px;

/*------------------------------------*\
     $COLORS
 \*------------------------------------*/
$colorBase: #0bb5e7;
$colorError: #ff0000;
$colorSuccess: #5cb85c;
$buttonColor: #000000;
$colorPrimario: #e5e5e5;
$colorBlanco: #ffff;
$backgroundColor: #f8f8f8;
$lightGray: #eeeeee;
$backgroundInput: #f6fafc;
/**
  * Primary colors
  */
// $colorPrimary: theme('colors.primary');
// $colorSecondary: theme('colors.secondary');
// $colorTertiary: theme('colors.tertiary');

/**
 * Grey Scale
 * This is mainly to hold back designers from adding 200 different greys ;)
 */
$colorDarkGrey: theme('colors.base');
// $colorGrey: theme('colors.gray.300');
// $colorSilver: theme('colors.gray.200');
// $colorAccent: theme('colors.gray.100');

// $colorBorders: $colorSilver;

/** 
 * Base Style
 */
$baseFont: 'PT Sans', sans-serif;
$baseHeadingFontWeight: normal;
$baseFontSize: 1rem;
$baseLineHeight: 1.5;
$baseLetterSpacing: 0.016em;
// $baseHeadingFontColor: $colorDarkGrey;
// $baseBoxShadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);

// Atmosphere
// $background: color-grey(lightgrey);
// $textColor: color-grey(lightgrey);
// $textcolorStrong: color-grey(lightgrey);
$borderColor: #4d4d4d;
$borderColorGray: #999;

// Links  //////////////////////
// $linkColor: #0f69b4;
// $linkColorHover: #003f84;
// $linkColorVisited: #2b83e4;
// $linkColorFocus: $linkColorVisited;
// $linkColorDisabled: lighten($linkColorVisited, 0.4);

// Toast
$rt-toast-width: 320px !default;
$rt-toast-background: #ffffff !default;
$rt-toast-min-height: 64px !default;
$rt-toast-max-height: 800px !default;

/*------------------------------------*\
     $DATE-PICKER
 \*------------------------------------*/

$datepicker__background-color: #f0f0f0 !default;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: #216ba5 !default;
$datepicker__text-color: #000 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten(
  $datepicker__muted-color,
  10%
) !default;

$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.45rem !default;
$datepicker__triangle-size: 8px !default;
