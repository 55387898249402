@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');

@import './base/variables.scss';
@import './base/heading.scss';
@import './base/forms.scss';
@import './base/links.scss';
@import './base/buttons.scss';
@import './base/titles.scss';
@import './base/toast.scss';
@import './animations/bounce.scss';

#univo__root {
  position: relative;
}

html {
}

body {
  margin: 0;
  font-family: 'PT Sans', sans-serif;
}

hr {
  border-color: rgba($borderColor, 0.2);
}

.disabled {
  // z-index: -1 !important;
  position: relative;
}

.grid {
  display: grid;
}
.flex {
  display: flex;
  gap: 8px;
}
.align-center {
  align-items: center;
}
.justify-start {
  justify-content: flex-start;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
}

.copy-item {
  color: $colorBase;
  cursor: pointer;
}
.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* width */

@import './content/layout-public.scss';
@import './content/layout-private.scss';
@import './content/user-container.scss';
@import './content/client-container.scss';
@import './content/login-container.scss';
@import './content/news.scss';
@import './content/sessions.scss';
@import './content/dashboard.scss';
@import './content/reports.scss';
@import './components/tabs.scss';
// previews
@import './content/previews/landing.scss';
@import './content/previews/login.scss';
@import './content/previews/platform.scss';
//
@import './components/avatars.scss';
@import './components/header.scss';
@import './components/statusIndicator.scss';
@import './components/actionMenu.scss';
@import './components/menu.scss';
@import './components/editor.scss';
@import './components/table.scss';
@import './components/pagination.scss';
@import './components/loader.scss';
@import './components/skeleton.scss';
@import './components/search-input.scss';
@import './components/category.scss';
@import './components/alert.scss';
@import './components/editor-markdown.scss';
@import './components/box-participants.scss';
@import './components/input-color.scss';
@import './components/modal.scss';
@import './components/switch.scss';
@import './components/assignDate.scss';
@import './components/wizard.scss';
@import './components/stepper.scss';
@import './content/pillar-container.scss';
@import './components/link2.scss';
@import './content/announcements/announcements.scss';
@import './content/future-sessions.scss';
@import './content/benefits.scss';
@import './content/sessionTables.scss';
@import './components/range-picker.scss';
@import './components/tablePaginationActions.scss';
@import './components/modalRelationGroup.scss';
@import './components/tabs-user.scss';
@import './components/editableCell.scss';

@import './content/workshopcreation.scss';
@import './components/indicator.scss';
@import './content/course.scss';
@import './content/info-mentee.scss';
@import './content/historial-actividad.scss';
