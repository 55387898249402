.link {
  display: flex;
  gap: 4px;
  color: rgba($colorBase, 0.7);
  cursor: pointer;
  text-decoration: none;
  align-items: center;
  font-size: 14px;
}

.icon-link {
  color: $colorBase;
}
.link--breadcrumb-prev {
  display: flex;
  gap: 8px;
  color: #616161;
  cursor: pointer;
  text-decoration: none;
  align-items: center;
  font-size: 14px;
}

.link:hover {
  color: $colorBase;
}

.MuiBreadcrumbs-ol {
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  align-items: end !important;
}
