.subtitle {
  font-size: 16px;
  color: rgba(65, 64, 70, 0.6);
}
.subtitle--big {
  font-size: 24px;
  color: #000;
  font-weight: 700;
}
.subtitle--strong {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 600px) {
  .subtitle--strong {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
