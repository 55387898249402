.preview {
  &__color-container {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    align-items: start;

    .description-container {
      display: flex;
      gap: 4px;
    }
    .description {
      color: #828282;
    }
  }
  &__landing {
    display: grid;
    align-content: start;
  }
}
.divider-title {
  background: #e42313;
  width: 56px;
  height: 8px;
  border-radius: 8px;
}
.landing {
  // banner section
  &__button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    background-color: #003965;
    width: 100%;
    color: #ffffff;
  }

  &__banner {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 50px 59px 69px 0;
    background-color: black;
    &--mobile {
      display: none;
    }
  }
  &__announcement-info {
    display: grid;
    align-items: start;
    justify-content: flex-start;
    justify-items: start;
  }
  &__form-container {
    display: flex;
    justify-content: flex-end;
  }
  &__form {
    display: grid;
    padding: 40px 60px 68px 60px;
    background-color: white;
    border-radius: 8px;
    row-gap: 30px;
    &--mobile {
      display: none;
    }
  }
  &__form-title {
    font-weight: 700;
    size: 20px;
  }

  .title-section {
    size: 32px;
    font-weight: 700;
  }

  //description
  &__description {
    display: grid;
    justify-content: center;
    padding: 70px 0;
  }
  &__icon-container {
    display: flex;
    gap: 8px;
  }
  &__icon-item-button {
    height: 30px;
    width: 30px;
    border: 1px solid #deedf2;
    background-color: #fafcfd;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    &--active {
      background-color: $colorBase;
    }
  }
  &__icon-image {
    filter: brightness(1) invert(0);
    &--active {
      filter: brightness(0) invert(1);
    }
  }
  &__items {
    display: flex;
    gap: 30px;
  }
  &__description-item {
    width: 56px;
    height: 56px;
    display: flex;
  }
  &__platform-description {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    align-items: center;
  }
  &__platform-text {
    display: grid;
    gap: 24px;
  }

  //allys
  &__allys-container {
    background-color: #f2f2f2;
    padding: 80px;
    justify-items: center;
    align-content: start;

    display: grid;
    gap: 28px;
  }
  &__allys-image {
    display: block;
    max-width: 100%;
    &--mobile {
      display: none;
    }
  }
}

.announcement {
  &__client {
    display: grid;
    background-color: white;
    border-radius: 0 8px 8px 0;
    grid-gap: 24px;
    gap: 24px;
    padding: 8px;
    grid-template-columns: auto auto;
    justify-content: flex-start;
  }
  &__title {
    color: white;
    font-weight: 700;
    font-size: 48px;
    max-width: 530px;
    margin-left: 70px;
    text-shadow: 2px 2px 2px rgb(83 80 80);
  }
  &__image-container {
    max-height: 60px;
    img {
      object-fit: cover;
      height: 100%;
    }
  }
}
.item-container {
  display: grid;
  justify-items: center;
  max-width: 220px;
  align-content: start;
  text-align: center;
}

.us-text {
  text-align: center;
  max-width: 900px;
}
.container {
  max-width: 1050px;
  display: grid;
  gap: 24px;
  justify-items: center;
  padding: 24px;
}

.filter-image {
  filter: grayscale(1);
}

.preview__footer {
  background-color: #1f1f1f;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__text {
  color: #bdbdbd;
  font-size: 16;
  &--secondary {
    color: #fa541c;
  }
}

@media (max-width: 700px) {
  .landing {
    // banner section
    &__banner {
      display: none;
      &--mobile {
        display: grid;
      }
    }
    &__announcement-info {
      display: grid;
      align-items: start;
      justify-content: flex-start;
      justify-items: start;
      padding: 35px 0;
      gap: 200px;
    }
    &__form-container {
      display: flex;
      justify-content: flex-end;
    }
    &__form {
      display: grid;
      padding: 40px 60px 68px 60px;
      background-color: white;
      border-radius: 8px;
      row-gap: 30px;
      &--mobile {
        display: grid;
        padding: 45px 40px;
        box-shadow: 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 16px 16px;
        gap: 40px;
      }
    }
    &__form-title {
      font-weight: 700;
      size: 20px;
    }

    .title-section {
      size: 32px;
      font-weight: 700;
    }

    //description
    &__description {
      justify-content: center;
      padding: 70px 0;
    }
    &__image-description {
      width: 330px;
      max-width: 100%;
    }
    &__icon-container {
      display: flex;
      gap: 8px;
    }
    &__icon-item-button {
      height: 30px;
      width: 30px;
      border: 1px solid #deedf2;
      background-color: #fafcfd;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      &--active {
        background-color: $colorBase;
      }
    }
    &__icon-image {
      filter: brightness(1) invert(0);
      &--active {
        filter: brightness(0) invert(1);
      }
    }
    &__items {
      display: flex;
      gap: 30px;
      flex-direction: column;
    }
    &__description-item {
      width: 56px;
      height: 56px;
      display: flex;
    }
    &__platform-description {
      display: grid;
      grid-template-columns: auto;
      gap: 24px;
      align-items: center;
      justify-items: center;
    }
    &__platform-text {
      display: grid;
      gap: 24px;
    }

    //allys
    &__allys-container {
      background-color: #f2f2f2;
      padding: 80px;
      justify-items: center;
      align-content: start;
      gap: 28px;
    }
    &__allys-image {
      display: none;
      &--mobile {
        display: block;
      }
    }
  }

  .container {
    display: grid;
    gap: 24px;
    justify-items: center;
    padding: 28px;
  }
  .preview__footer {
    background-color: #1f1f1f;
    padding: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 60px;
    align-items: center;
  }
  .announcement {
    &__client {
      display: grid;
      background-color: white;
      border-radius: 0 8px 8px 0;
      grid-gap: 24px;
      gap: 24px;
      padding: 8px;
      grid-template-columns: auto auto;
      justify-content: flex-start;
    }
    &__title {
      color: white;
      font-weight: 700;
      font-size: 32px;
      max-width: 300px;
      margin-left: 35px;
      text-shadow: 2px 2px 2px rgb(83 80 80);
    }
  }
}

.char-counter {
  color: #595959;
  size: 12px;
}
