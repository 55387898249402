.news {
  &__card {
    border: 1px solid #e6e6e6;
    padding: 30px 40px;
    border-radius: 16px;
    background-color: #ffffff;
    display: grid;
    gap: 24px;
    .news-heading {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .title-section {
      display: flex;
      gap: 8px;
    }
    .details-section {
      display: flex;
      flex-direction: row;
      gap: 24px;
      align-items: center;
    }
    .menu-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      border: 1px solid #0bb5e7;
      background-color: #ffffff;
      .icon {
        color: #0bb5e7;
      }
      &--active {
        background-color: #0bb5e7;
        .icon {
          color: #ffffff;
        }
      }
    }
    .news-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .image-container {
        width: 220px;
        height: 220px;
        overflow: hidden;
        display: flex;
        img {
          max-width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }
    .news-info {
      color: #353535;
      opacity: 0.5;
    }
    .news-content-info {
      max-width: 700px;
    }
  }
}
.search-paginator {
  justify-content: center;
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  span {
    @apply mr-2 text-gray-860 inline-block;
  }
  .MuiPaginationItem-root {
    color: #002329;
    font-weight: 700;
  }
  .Mui-selected {
    color: #fafafa;
    background-color: #002329 !important;
  }
  .MuiSvgIcon-root {
    color: #002329;
  }
}
