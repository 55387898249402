.iconInput {
    .MuiInput-underline:before {
        border-bottom: none;
    }
}

.selected {
    color: #FA5000;
    transform: scale(1.3);
    animation: transform .5s;
}

.error-message2 {
    font-size: 0.875rem;
    color: rgba(229, 62, 62, 1);
    font-family: "PT Sans", sans-serif;
    font-weight: 100;
    margin-top: 0;
}

.icon-grid {
    margin: 0 auto;
}

.current-icon {
    margin-right: 15px;
    font-weight: 700;
}

.select-icon {
    margin: 50px 0;
}

.icon-grid:hover {
    color: orange;
    transform: scale(1.2);
    animation: transform .5s;
}
.pillar__icon{
    display: flex;
    padding: 8px;
    &--active{
        background-color: #0BB5E7;
        color:#FFFF;
        border-radius: 8px;
    }
  
}
.pillar__icon:hover{
    background-color: #0BB5E7;
    color:#FFFF;
    border-radius: 8px;
}