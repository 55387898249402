.user-container {
  .indicator-container {
    display: grid;
    grid-template-columns: repeat(5, minmax(200px, 1fr));
    gap: 13px;
    padding-bottom: 20px;
    .indicator {
      height: 125px;
      border-radius: 10px;
      cursor: pointer;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 45px;
      border: 2px solid transparent;
      transition: border-color 0.3s ease;

      &:hover {
        border-color: #0bb5e7;
      }

      .indicator-container {
        display: flex;
        align-items: center;
        padding-bottom: 0px;
      }
      .indicator-value {
        font-size: 34px;
        font-weight: 700;
      }
      .indicator {
        font-size: 34px;
        font-weight: 700;
      }
      .icon-indicator {
        font-size: 31px;

        color: #0bb5e7;
      }
      .description {
        font-size: 14px;
      }
    }
    .active-indicator {
      border-color: #0bb5e7;
    }
  }
  @media (max-width: 768px) {
    .indicator-container {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }
}
