.platform {
  &__logo-container {
    max-width: 170px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__logo {
    max-width: 100%;
    height: auto;
  }
  &__navbar-container {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
  }
  &__navbar-actions {
    display: flex;
    gap: 30px;
    align-items: center;
  }
  &__search-input {
    position: relative;
    align-self: center;
    .search-input__icon {
      color: #434343;
      right: 0;
      opacity: 0.7;
    }
    input {
      border: 1px solid #bfbfbf;
      border-radius: 12px;
    }
  }
  &__icon {
    color: #262626;
  }
  &__menu {
    padding: 48px 25px;
    justify-self: start;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    hr {
      width: 100%;
    }
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      align-items: flex-start;
      display: flex;
      font-size: 14px;

      .items,
      li {
        display: flex;
        justify-content: center;
        gap: 16px;
        color: #bfbfbf;
        .material-icons {
          color: #bfbfbf;
        }
      }
      .items:first-child {
        color: #434343;
        .material-icons {
          color: #434343;
        }
      }
    }
  }
  &__content-container {
    display: grid;
    grid-template-columns: auto 1fr;
  }
  //content
  &__image-container {
    min-height: 305px;
    background-color: black;
    position: relative;
  }
  &__welcome-container {
    padding: 20px;
    background-color: #e5e5e5;
  }
  &__welcome-text-container {
    background-color: white;
    transform: translate(0, -50%);
    padding: 52px 40px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 16px;
    .welcome-username {
      font-size: 24px;
      font-weight: 700;
    }
    .welcome-message {
      font-size: 16px;
      color: #333333;
    }
  }
  &__user-actions {
    padding: 0px 20px 20px 20px;
    background-color: #e5e5e5;
    min-height: calc(100vh - 384px);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
    gap: 32px;
    align-content: start;
  }
  &__card {
    padding: 48px 20px;
    display: grid;
    gap: 30px;
    .title-container {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 16px;
      justify-content: center;
      align-items: center;
    }
    .title {
      font-size: 18px;
      font-weight: 700;
    }
    .description-card {
      font-size: 14px;
      color: #434343;
    }
  }
}

.menu {
  &__footer {
    bottom: 0;
    justify-items: start;
    align-items: end;
    align-content: end;
    .link {
      color: #636363;
      font-size: 12px;
      text-decoration: underline;
    }
    .brand {
      color: #636363;
      font-size: 12px;
      text-transform: uppercase;
    }
    .footer-text {
      color: #636363;
      font-size: 12px;
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
