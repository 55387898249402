/*------------------------------------*\
    $TOAST
\*------------------------------------*/
.Toastify {
  &__toast-container {
    z-index: 100;
    -webkit-transform: translate3d(0, 0, #{99999}px);
    position: fixed;
    padding: 4px;
    width: $rt-toast-width;
    box-sizing: border-box;
    color: #fff;
    &--top-left {
      top: 1em;
      left: 1em;
    }
    &--top-center {
      top: 1em;
      left: 50%;
      margin-left: calc($rt-toast-width / 2);
    }
    &--top-right {
      top: 1em;
      right: 1em;
    }
    &--bottom-left {
      bottom: 1em;
      left: 1em;
    }
    &--bottom-center {
      bottom: 1em;
      left: 50%;
      margin-left: calc($rt-toast-width / 2);
    }
    &--bottom-right {
      bottom: 1em;
      right: 1em;
    }

    .Toastify__toast {
      position: relative;
      min-height: $rt-toast-min-height;
      box-sizing: border-box;
      margin-bottom: 1rem;
      padding: 8px;
      border-radius: 8px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1),
        0 2px 15px 0 rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: space-between;
      max-height: $rt-toast-max-height;
      overflow: hidden;
      cursor: pointer;
      direction: ltr;

      &--default {
        background: $rt-toast-background;
        color: #4d4d4d;
      }
      &-body {
        margin: auto 0;
        flex: 1;
      }

      .Toastify__toast-body {
        align-self: center;
        margin: 10px !important;
        .flex {
          display: flex;
          span {
            margin-left: 10px;
          }
        }
      }

      .Toastify__close-button {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        background: transparent;
        outline: none;
        border: none;
        padding: 0;
        cursor: pointer;
        opacity: 0.7;
        transition: 0.3s ease;
        align-self: flex-start;
        &--default {
          color: #000;
          opacity: 0.3;
        }

        &:hover,
        &:focus {
          opacity: 1;
        }
      }

      @keyframes Toastify__trackProgress {
        0% {
          transform: scaleX(1);
        }
        100% {
          transform: scaleX(0);
        }
      }

      .Toastify__progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        z-index: 100;
        opacity: 0.7;
        background-color: rgba(255, 255, 255, 0.7);
        transform-origin: left;

        &--animated {
          animation: Toastify__trackProgress linear 1 forwards;
        }

        &--controlled {
          transition: transform 0.2s;
        }

        &--error {
          background: $colorError;
        }
        &--success {
          background: $colorSuccess;
        }
      }
    }
  }
}
