.editor {
  &__error {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  }
  &__wrapper {
    border: 1px solid #deedf2;
    border-bottom: 1px solid #257fa4;
    overflow-y: scroll;
  }
  &__toolbar {
    border-bottom: 1px solidc #deedf2;
  }
}

.rdw-option-wrapper {
  border: 0px !important;
}
.rdw-option-wrapper {
  border: 0px !important;
}
.rdw-editor-main {
  height: 150px !important;
  overflow: auto;
  box-sizing: border-box;
}
