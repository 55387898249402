.table-container {
  background-color: white;
  border-radius: 8px;
  min-height: 325px;
  .table-sort-label {
    :focus {
      color: #000000;
    }
    svg {
      opacity: 1;
      color: rgba(0, 0, 0, 0.54);
    }
  }
  .actions-table {
    // margin: auto;
    // display: flex;
    padding: 30px;
    display: flex;
    /* grid-template-columns: repeat(auto-fit, minmax(76px, 1fr)); */
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    justify-content: space-between;
    &__action-container {
      display: flex;
      justify-content: flex-end;
      gap: 24px;
      align-items: center;
      .__btnLink {
        color: #0bb5e7;
        font-weight: bold;
        width: fit-content;
      }
      .disabled {
        color: #b1b1b1;
        cursor: not-allowed;
      }
    }
    &__search {
      display: flex;
      justify-self: end;
      width: 269px;
    }

    &__title {
      font-size: 26px;
      font-weight: 700;
    }
    button {
      height: 40px;
      width: 220px;
      padding: 0px 15px;
      margin: 0px;
    }
  }
  .delete-filter-container {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px;
    ._btn {
      color: #b1b1b1;
      font-weight: bold;
      width: fit-content;
      font-size: 12px;
    }
  }
  .MuiTableRow-root.Mui-selected {
    background-color: transparent;
    &:hover {
      background-color: #0bb5e71a;
    }
  }

  .MuiTableCell-root {
    padding: 10px 25px;
    .checkbox-check {
      color: #0bb5e7;
    }
  }

  a: {
    text-decoration: none;
    color: #52575c;
  }
  th {
    font-weight: 700;
    vertical-align: baseline;
  }

  tr {
    align-items: end;
  }
  .inactive {
    td {
      opacity: 0.5;
    }

    td:last-child {
      text-align: end;
      opacity: 1;
    }
  }

  td:last-child {
    text-align: end;
  }
  tr:hover {
    background-color: rgba(11, 181, 231, 0.1);
  }
  .action-container {
    position: relative;
    cursor: pointer;
    height: 30px;
    width: 30px;
    border: 1px solid #0bb5e7;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      color: #0bb5e7;
      font-size: 16px;
    }
  }
  .active-action {
    background: #0bb5e7;
    .icon {
      color: #ffffff;
    }
  }
}
