.assign-date {
  padding: 20px;
  .assign-date-select {
    max-height: 400px;
    overflow: scroll;
    padding-bottom: 20px;

    &__day {
      .assign-date__hours {
        display: flex;

        &--hour {
          padding: 5px 10px;
          margin-right: 20px;
          border-radius: 20px;
          border: 1px solid $borderColorGray;
          color: $borderColorGray;

          &.active {
            color: white;
            background-color: $colorBase;
            border: 0 none;
          }
        }
      }
    }
  }
  .assign-date-button {
    border-top: 1px solid #ddd;
    padding: 10px 0;
  }
}
