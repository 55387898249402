.report {
  &-container {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(3, 1fr);
  }

  &__card {
    display: grid;
    align-items: center;
    padding: 24px;
    grid-template-columns: auto auto;
    gap: 24px;
    justify-content: center;
    .title {
      font-weight: 700;
      color: #000000;
      font-size: 16px;
    }
    .subtitle {
      color: $colorBase;
      display: flex;
      cursor: pointer;
      &--loading {
        color: $lightGray;
      }
    }
    .text-container {
      display: grid;
    }
  }
}
