/*------------------------------------*\
    $TIPOGRAFÍA
\*------------------------------------*/

// HEADINGS ====================================================

h1,
h2,
h3 {
  margin: 0;
  text-rendering: optimizelegibility;
}

// Set headers line-height, margin bottom and font size
@each $header, $font-size,
  $line-height in (h1, 32px, 1) (h2, 26px, 1) (h3, 1.25rem, 1)
{
  #{$header},
  .#{$header} {
    font-size: $font-size;
    line-height: $line-height;
  }
}

// TYPOGRAFÍA ====================================================

p,
ol,
ul,
dl,
address,
small,
span {
  line-height: $baseLineHeight;
}

p {
  word-spacing: 0.016em;
}

small {
  font-size: $baseFontSize - 2;
  color: $colorDarkGrey;
}

li ul,
li ol {
  margin: 0;
}

b,
strong {
  font-weight: bold;
}

// hr {
//   clear: both;
//   display: block;
//   margin: $baseLineHeight 0;
//   padding: 0;
//   height: 1px;
//   border: 0;
//   border-top: 1px solid $borderColor;
// }
