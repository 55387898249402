.action-menu {
  position: absolute;
  padding: 8px;
  background: $colorBlanco;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 8px;
  top: 50%;
  z-index: 1;
  display: none;
  &__item:hover {
    color: rgba(17, 17, 17);
  }
  &__item {
    padding: 8px;
    white-space: nowrap;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    color: rgba(17, 17, 17, 0.5);
    &--featured {
      color: #0bb5e7;
      cursor: pointer;
    }
    &--warning {
      color: #e31e2f;
      cursor: pointer;
    }
    &--disabled {
      color: rgba(17, 17, 17, 0.2);
      cursor: not-allowed;
    }
    &--disabled:hover {
      color: rgba(17, 17, 17, 0.2);
    }
  }
}
.action-menu-container {
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: end;
}

.action-container {
  cursor: pointer;
}

.action-menu--visible {
  position: absolute;
  background: $colorBlanco;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08);
  display: grid;
  border-radius: 8px;
  top: 68px;
  z-index: 1;
  width: fit-content;
  min-width: 110px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.action-menu:last-child {
  position: absolute;
  padding: 8px;
  background: $colorBlanco;
  border: 1px solid black;
  box-shadow: 0px 4px 16px green;
  border-radius: 8px;
  top: 50%;
  z-index: 1;
  display: none;
}
.action-menu--visible::before {
  content: '';
  position: absolute;
  right: 40%;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #bfbdbd;
  top: -11px;
  z-index: 1;
}
.action-menu--visible::after {
  content: '';
  position: absolute;
  right: 41%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent; /* Tamaño interno */
  border-right: 10px solid transparent;
  border-bottom: 10px solid white; /* Color del triángulo interno */
  top: -10px; /* Alinear con el borde externo */
  z-index: 2; /* Asegurar que esté encima del borde */
}
