.input_color_container {
  display: flex;
  align-items: center;
  width: 200px;
  min-width: 260px;
  max-width: 300px;
}

.input_color_base {
  border-right: 1px solid #D6D6D6;
  padding-left: 8px;
}

.input_color_formatter {
  padding: 2px;
  margin: 0;
  background-color: transparent;
  width: 32px;
  height: 32px;
  border: none;
}

.input_color_formatter::-webkit-color-swatch {
  border: none;
  border-radius: 4px;
}

.input_color_prefix {
  padding: 12px;
  background-color: #EFEFEF;
  border-radius: 4px 0 0 4px;
}