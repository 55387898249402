.preview_login_container {
  display: grid;
  grid-template-columns: 370px 1fr;
}

.preview_login_container a {
  font-weight: bold;
}

.preview_login_banner {
  min-height: calc(100vh - 89px);
  background-size: cover;
  width: 360px;
}

.preview_login_content {
  display: flex;
  gap: 16px;
  justify-content: space-around;
  flex-wrap: wrap-reverse;
}

.preview_login_content>div {
  margin: 80px 0 20px 0;
}

.preview_login_description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 360px;
}

.preview_login_description p {
  margin-left: 28px;
  font-weight: 700;
}

.preview_login_description img {
  margin: 0 auto;
  margin-bottom: 10px;
  max-width: 240px;
}

.preview_login_description ul {
  list-style: none;
  padding: 0;
}

.preview_login_description li {
  padding: 10px 0;
  display: flex;
  row-gap: 10px;
}

.preview_login_description li:last-child {
  margin-right: 10px;
}

.preview_login_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 320px;
}

.preview_login_form img {
  width: 200px;
}

.preview_login_form>img {
  margin-top: 20px;
}

.preview_login_form_header {
  display: flex;
  flex-direction: column;
  grid-row-gap: 40px;
  margin-bottom: 20px;
}

.preview_login_form_header strong {
  font-size: 20px;
  color: #636363;
}

.preview_login_form_body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.preview_login_form_body button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}

.section_full_width {
  display: flex;
  justify-content: space-around;
  align-items: center;
  column-gap: 36px;

  .preview_login_info {
    display: flex;
    gap: 8px;
    align-items: start;
    padding: 4px;
    width: 340px;
    min-width: 240px;
    color: #828282;
    font-size: 14px;
  }
}

.info_svg {
  color: #0bb5e7;
  height: 12px;
}

@media (max-width: 760px) {
  .section_full_width {
    display: flex;
    flex-direction: column;
  }
}