.modal-relation-group {
  .modal {
    &__container {
      display: grid;
      position: absolute;
      background-color: $colorBlanco;
      border-radius: 8px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 490px;
      height: 322px;
    }
    &--full {
      height: 100%;
      width: 100vw;
      justify-content: stretch;
      display: grid;
      overflow: scroll;
      .title {
        font-size: 24px;
        font-weight: 700;
      }
    }

    &__header {
      padding: 16px 32px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      gap: 32px;
      height: 24px;
      .icon {
        color: #0bb5e7;
        cursor: pointer;
      }
      .title {
        font-size: 16px;
        font-weight: 700;
        color: #000000;
      }
    }
    &__content {
      padding: 16px 32px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .custom_modal {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        margin-bottom: 24px;
        backdrop-filter: blur(4px);
        .container-input-form {
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;
          .input-form {
            width: 412px;
            height: 32px;
            border-bottom: 2px solid #257fa4;
            border-radius: 4px 4px 0 0;
            background-color: #f6fafc;
            color: #767676;
            font-size: 16px;
          }
          .error {
            border-bottom: 2px solid red;
            color: red;
          }
          .container-error {
            font-size: 12px;
            color: red;
          }
        }
      }

      .grid {
        &--24 {
          gap: 24px;
        }
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        &--light {
          color: #000000;
          opacity: 0.3;
        }
      }
      .modal__action-container {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 12px;
        gap: 12px;
        margin-top: 16px;
      }
    }
  }
}
