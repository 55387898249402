.login-container {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr auto;
  &__content {
    height: 100%;
  }

  &__bg-image {
    background-image: url('../../images/bg-login.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 600px;
    margin-top: auto;
    margin-bottom: auto;
    align-self: center;
    margin-top: 100px;
  }
  .card-login {
    box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
    height: 100%;
    display: grid;
    padding: 0 16px 0 24px;
    justify-content: center;
    align-content: center;

    &__image {
      height: 38px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 40px;
    }

    .form__button {
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      border-radius: 0;
      font-weight: 700;
      color: #fff;
      text-align: center;
      width: 100%;
      display: block;
      margin-top: 3rem;
    }
  }

  .powered {
    font-weight: 700;
    color: #979797;
    position: fixed;
    bottom: 20px;
    right: 20px;
  }

  // .button {
  //   margin-top: 20px;
  // }
}

@media (max-width: 600px) {
  .login-container__bg-image {
    display: none;
  }
}
