.user-modal {
  display: grid;
  gap: 24px;
  padding-right: 20px;
  &__container {
    display: grid;
    gap: 16px;
    max-height: 400px;
    overflow-y: scroll;
  }
  &__item {
    display: grid;
    grid-template-columns: auto auto;
    padding: 8px;
    gap: 4px;
    justify-content: flex-start;
    border-radius: 4px;
    align-items: center;
    .icon {
      color: #0bb5e7;
    }
  }
  &__item:hover {
    background-color: rgb(11, 181, 231, 0.2);
  }
}
