.container-summary {
  display: grid;
  grid-template-columns: auto auto;
  align-content: start;
  gap: 20px;

  .institution-section {
    grid-column: 1;
  }

  .category-section {
    grid-column: 2;
    grid-row: 1;

    .category-detail {
      border-radius: 15px;
      background-color: #f2f1f2;
      width: fit-content;
      padding: 7px 0 10px;
      display: flex;
    }

    .FiberManualRecordIcon {
      color: #0bb5e7;
    }
  }
}
