.modal {
  backdrop-filter: blur(5px);
  &__title {
    padding: 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .title {
      display: inline-block;
    }
    .title__close {
      float: right;
      outline: none;
      border: 0 none;
      background: none;
      .close__icon {
        color: $colorBase;
      }
    }
    &--secondary {
      background-color: $colorBase;
      color: $colorBlanco;
      display: grid;
      grid-template-columns: 1fr 200px;
    }
  }
  &__container {
    display: grid;
    position: absolute;
    background-color: $colorBlanco;
    border-radius: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &--full {
    height: 100%;
    width: 100vw;
    justify-content: stretch;
    display: grid;
    overflow: scroll;
    .title {
      font-size: 24px;
      font-weight: 700;
    }
  }

  &__header {
    padding: 16px 32px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    gap: 32px;
    .icon {
      color: #0bb5e7;
      cursor: pointer;
    }
    .title {
      font-size: 16px;
      font-weight: 700;
      color: #000000;
    }
  }
  &__content {
    padding: 40px 8px 56px 30px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .grid {
      &--24 {
        gap: 24px;
      }
    }
    .title {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      &--light {
        color: #000000;
        opacity: 0.3;
      }
    }
  }
  &__action-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 12px;
    &--2-columns {
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
    &--1-column {
      grid-template-columns: minmax(100px, 300px);
      display: grid;
      justify-content: center;
    }
  }
  .center {
    justify-content: center;
    justify-items: center;
  }

  //pillars modal
  .box {
    border: 1px solid #d6d6d6;
    border-radius: 6px;
    padding: 12px;
    min-width: 500px;
    display: grid;
    row-gap: 12px;
    hr {
      width: 100%;
    }
    &__header {
      display: flex;
      justify-content: space-between;
    }
    &__container {
      max-height: 400px;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
.char-counter {
  font-size: 12px;
  justify-self: end;
}
