.stepper {
  display: grid;
  gap: 8px;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  align-items: center;
  &__indicator {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 1px dashed rgba(#2c2c2c, 0.5);
    &--done {
      border: none;
      background-color: rgba(5, 81, 105, 0.5);
      display: flex;
      padding: 3px;
      justify-content: center;
      align-items: center;
    }
  }
  &__icon {
    color: white;
    font-size: 14px;
  }

  &-text {
    font-size: 16;
    color: rgba(#055169, 0.5);
  }
  &-text--active {
    font-weight: 600;
    color: #055169;
  }
}
