.Appbar-user {
  box-shadow: 0px 4px 4px 0px #00000040 !important;
  .MuiTabs-flexContainer {
    .MuiTab-root {
      padding: 6px 24px;
      font-family: Roboto, sans-serif;
      text-transform: none;
      .MuiTab-wrapper {
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
    .Mui-selected {
      color: #51b3e2;
      font-weight: 700;
    }
  }
}
