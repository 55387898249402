.container-editableCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
  .textField-form {
    .MuiOutlinedInput-root {
      border-radius: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #f5fafd;
      height: 36px;
      & fieldset {
        border-color: #deedf2;
        border-bottom-color: #257fa4;
        border-bottom-width: 2px;
        margin-top: 4px;
      }
      &:hover {
        &fieldset {
          border-color: #deedf2;
          border-bottom-color: #257fa4;
          border-bottom-width: 2px;
        }
      }
      & input {
        padding: 20px 16px 2px;
        color: #25282b80 !important;
      }

      & legend {
        display: none;
      }
      .MuiSelect-outlined.MuiSelect-outlined {
        background-color: transparent;
        padding: 13px 36px 2px 22px;
        color: #25282b80;
      }
    }
    .MuiSelect-icon {
      color: #0bb5e7;
    }
    .Mui-focused {
      border-color: #257fa4;
      & fieldset {
        border-color: #deedf2;
        border-bottom-color: #257fa4;
        border-bottom-width: 2px;
      }
      & input {
        padding: 20px 16px 2px;
        color: #25282b80;
      }
      .MuiInputLabel-root {
        margin-top: 10px;
        color: #25282b80;
      }
      .MuiSelect-select {
        &:focus {
          background-color: transparent;
          padding: 13px 36px 2px 22px;
          color: #25282b80;
        }
      }
    }

    .MuiInputLabel-root {
      color: #767676;
    }
    .MuiInputLabel-outlined {
      .Mui-focused {
        color: #767676;
        margin-top: 10px;
      }
    }
    .MuiSelect-icon {
      color: #0bb5e7;
    }
    .select-multiple {
      .MuiSelect-outlined.MuiSelect-outlined {
        padding: 13px 4px;
        &:focus {
          padding: 13px 4px;
        }
        .chips-container {
          gap: 4px;
          display: flex;
          flex-wrap: wrap;
          .chips {
            margin: 2;
            background-color: #0bb5e7;
            color: #e1f5fe;
            border-radius: 4px;
            border-color: #f0f0f0;
            width: 166px;
            .delete-button {
              margin-left: 8px;
              color: #e1f5fe;
              &:hover {
                background-color: #e1f5fe;
              }
            }
            // .MuiChip-label {
            //   padding-right: 0px;
            // }
          }
          .more-options {
            width: fit-content !important;
            // .MuiChip-label {
            //   padding-right: 12px;
            // }
          }
        }
      }
    }
    .MuiSelect-root.MuiSelect-multiple {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 10px; // Espaciado para las opciones seleccionadas
      color: #25282b80;

      .MuiSelect-chip {
        background-color: #deedf2;
        margin: 4px;
        padding: 4px 8px;
        border-radius: 16px;
        font-size: 14px;
      }
    }

    .MuiSelect-select.MuiSelect-selectMultiple {
      padding: 8px 10px;
      color: #25282b80;
    }

    // Responsivo
    @media (max-width: 600px) {
      .MuiSelect-root.MuiSelect-multiple {
        max-height: 120px;
        overflow-y: auto;
      }
    }
  }
  .container-actions-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .MuiIconButton-root {
      color: #0bb5e7;
      padding: 4px;
    }
  }
}
