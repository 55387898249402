.mail-creation-container {
    width:100% !important;
    padding: 0px 0px 0px 24px;
  .Filter-control {
    color: #0bb5e7;
    font-weight: 700;
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: underline;
    }
  }
  .preview-title {
    font-weight: bold;
    padding: 20px;
    display: flex;
    color: gray;
    &-on {
      color: black;
    }
  }
  .preview-grid {
    height: 418px;
    background-color: rgb(250, 249, 249);

    .preview-logo {
      color: gray;
    }
    .preview-image {
      height: 90%;
      width: 90%;
    }
  }
}
