.range-picker {
  width: 269px;
  height: 36px;
  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
    .input-datepicker {
      width: 100%;
      height: 36px;
      border-radius: 100px !important;
      border: 1px solid #9bc3d3 !important;
      background-color: #f6fafc;
      display: flex;
      cursor: pointer;
      padding: 8px;
      align-items: center;
      justify-content: space-between;
      span {
        font-family: 'PT Sans', sans-serif;
        padding-left: 8px;
      }
      .color-span {
        color: #0bb5e7;
      }
      .black-span {
        color: #444444;
      }
      .content-btns {
        display: flex;
        align-items: center;
        gap: 4px;
        .MuiIconButton-root {
          width: fit-content;
          color: #0bb5e7;
          padding: 0;
          svg {
            font-size: 14px;
          }
          &:hover {
            background-color: transparent;
          }
        }
      }
      svg {
        color: #0bb5e7;
      }
    }
  }
  .react-datepicker {
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    .react-datepicker__header {
      border-bottom: 0;
      background-color: transparent;
      height: fit-content;
      .custom-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        .MuiIconButton-root {
          width: fit-content;
          color: #0bb5e7;
          padding: 0;
        }
        .month {
          text-transform: capitalize;
          font-size: 18px;
          font-weight: 700;
        }
      }
      .year-selector {
        width: 86px;
        margin-bottom: 11px;
        --border-opacity: 0;
      }
      .react-datepicker__day-names {
        .react-datepicker__day-name {
          width: 41.02px;
          height: 39.67px;
        }
      }
    }
    .react-datepicker__month {
      .react-datepicker__day {
        color: #444444;
        font-family: 'PT Sans', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 40px !important;
        text-align: center;

        width: 41px;
        height: 39.67px;

        &--selected {
          background-color: #0bb5e7 !important;
          color: #fff;
          font-weight: 700;
          border: 1px solid #0bb5e7;
          height: 39.67px !important;
        }
        &--in-range {
          background-color: #d5f4f4;

          .day-range {
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateX(20%);
          }
        }
        &--keyboard-selected {
          background-color: #0bb5e7 !important;
          color: #fff;
          font-weight: 700;
          height: 39.67px !important;
          border: 1px solid #0bb5e7;
        }
        &--in-selecting-range {
          background-color: #d5f4f4;

          &:hover {
            background-color: #0bb5e7;
          }
        }
      }
    }
  }
}
