/*------------------------------------*\
    $FORM
\*------------------------------------*/

/*------------------------------------*\
    $FORM
\*------------------------------------*/

label {
  font-size: 1rem;

  &.label-checkradio {
    font-weight: 400;
  }
}
.input__container {
  margin-bottom: 20px;

  label {
    --text-opacity: 1;
    color: #767676;
    color: rgba(118, 118, 118, var(--text-opacity));
  }
}

input[type='radio'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: $colorBase;
  width: 19px;
  height: 19px;
  border: 0.1em solid $colorBase;
  border-radius: 50%;
  display: grid;
  place-content: center;
}
.label__radio {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

input[type='radio']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  //transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em $colorBase;
}
input[type='radio']:focus {
  outline: solid $colorBase;
}

input[type='radio']:checked::before {
  transform: scale(1);
}

.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: '\00a0';
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid blue !important;
  pointer-events: none;
}

input,
textarea {
  border-width: 1px;
  --border-opacity: 1;
  border-color: #e8eaed;
  border-color: rgba(232, 234, 237, var(--border-opacity));
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  font-size: 0.875rem;
}

input[type='checkbox'] {
  width: auto;
  margin-right: 8px;
}
input[type='password'] {
  text-align: center;
  font-size: 1.5rem;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.25rem;
  color: rgba(0, 0, 0, 0.7);
}
input[name='email'] {
  color: rgba(0, 0, 0, 0.7);
}
input:focus,
textarea:focus,
select:focus {
  border: 1px;
  outline: 0;
}
input.error,
textarea.error {
  --border-opacity: 1;
  border-color: #cc0000;
  border-color: rgba(204, 0, 0, var(--border-opacity));
}
select {
  border-width: 1px;
  --border-opacity: 1;
  border-color: #e8eaed;
  border-color: rgba(232, 234, 237, var(--border-opacity));
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  font-size: 0.875rem;
  height: 2.5rem;
  z-index: 0;
  background-color: white;
  & + svg.icon-select {
    position: absolute;
    z-index: 10;
    background-color: white;
    height: 24px;

    top: 1.825rem;
    right: 0.25rem;
  }
}

.error-message {
  font-weight: normal;
  font-size: 0.875rem;
  --text-opacity: 1;
  color: #e53e3e !important;
  color: rgba(229, 62, 62, var(--text-opacity));
  visibility: visible;
  &--non-visible {
    visibility: hidden;
  }
}

.extra-message {
  font-size: 0.875rem;
  margin-top: 0.25rem;
  color: #777;
}

.field-radio-icon label {
  display: flex;
  align-items: center;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
  height: 32px;
  margin-bottom: 8px;

  input[type='radio'] {
    position: absolute;
    width: 0px;
    height: 0px;
    opacity: 0;
    & + svg {
      cursor: pointer;
      --text-opacity: 1;
      color: #cbd5e0;
      color: rgba(203, 213, 224, var(--text-opacity));
      width: 2rem;
      height: 2rem;
      margin-right: 0.5rem;
    }
    &:checked + svg {
    }
  }
}

.switch {
  display: flex;
  align-items: center;
  input[type='checkbox'] {
    border: 0px;
    position: relative;
    appearance: none;
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity));
    outline: 0;
    width: 52px;
    height: 26px;
    border-radius: 13px;
    transition: 0.5s;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  }
  input:checked[type='checkbox'] {
  }

  input[type='checkbox']::before {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: white;

    content: '';
    width: 26px;
    height: 26px;
    border-radius: 13px;
    transition: 0.5s;
    transform: scale(1.1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  input:checked[type='checkbox']::before {
    left: 26px;
  }
  input:focus[type='checkbox'] {
    border: none;
  }
}

.MuiFormControl-root {
  --bg-opacity: 1;
  background-color: rgb(246, 250, 252, var(--bg-opacity));
  border-radius: 4px;
  width: 100%;
  label {
    margin: 0;
    padding-left: 12px;
  }
  .MuiInputBase-input,
  .MuiAutocomplete-inputRoot {
    padding: 11px 16px;
    border: $colorBase;
  }

  .MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid $colorBase;
    pointer-events: none;
  }

  .MuiFormLabel-root.Mui-focused {
    --text-opacity: 1;
    color: #767676;
    color: rgba(118, 118, 118, var(--text-opacity));
    margin-top: 8px;
  }
  .MuiFormLabel-filled {
    margin-top: 8px;
  }
}

.input__container--cards {
  display: flex;
  justify-content: space-between;
  .card__item {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    text-align: center;
    padding: 10px;
    cursor: pointer;
    margin: 0 5px;
    width: 33.3%;
    h2 {
      font-weight: bold;
    }
    p {
      margin-top: 8px;
      font-size: 14px;
    }
    &.active {
      border-color: $colorBase;
      border-width: 2px;
    }
    &.card0 {
      background-color: #c5ebfe;
    }
    &.card1 {
      background-color: #a5f8ce;
    }
    &.card2 {
      background-color: #fefd97;
    }
  }
}
.input__container {
  .select-id-name {
    width: 100%;
  }
  label + .MuiInput-formControl {
    margin-top: 10px;
  }
  .MuiSelect-root {
    padding: 6px;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    text-align: center;
  }
  .MuiSelect-root::placeholder {
    font-size: 14px;
  }
}

.report-style-class {
  height: 100%;
}

// INPUT WRAPPER
.form-wrapper {
  display: grid;
  grid-gap: 4px;
  max-width: 320px;
  &--big {
    display: grid;
    grid-gap: 4px;
    max-width: 450px;
  }
  &--full {
    display: grid;
    grid-gap: 4px;
  }
  &__label {
    font-size: 16px;
    font-weight: 700;
    color: #111111;
    line-height: 1.5;
    max-width: 320px;
    &--bigger {
      font-weight: 700;
      color: #111111;
      font-size: 20px;
      line-height: 1.5;
      max-width: 320px;
    }
    &--description {
      font-weight: normal;
      font-size: 16px;
      color: #828282;
    }
  }
}

//INPUT IMAGE
.input-image {
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 16px 20px;
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  cursor: pointer;

  &__container {
    display: grid;
    gap: 12px;
    //contenedor del archivo cuando sea cargado
    .input-file {
      background-color: #f5f7f8;
      padding: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .file-icon {
      color: #808080;

      font-size: 14px;
      &--rotated {
        transform: rotate(40deg);
      }
      &--big {
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
  &__icon {
    color: $colorBase;
  }
  &__text {
    color: #414046;
    font-size: 16px;
  }
  &__field {
    display: none;
  }

  &__image--not-loaded {
    visibility: hidden;
  }
  &__file-container {
    display: flex;
    .image-container {
      height: 104px;
      width: 104px;
      border: 1px solid #d6d6d6;
      box-sizing: border-box;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    img {
      height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
    .image-eraser {
      cursor: pointer;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background-color: #0bb5e7;
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .icon {
      font-size: 14px;
      color: #ffffff;
    }
  }
  &--disabled {
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 16px 20px;
    border: 1px solid #d6d6d6;
    border-radius: 6px;
    .input-image__text {
      color: #828282;
    }
    .input-image__icon {
      color: #828282;
    }
  }
}

.image-uploaded {
  color: #333333;
  &__icon {
    color: #808080;
    cursor: pointer;
    font-size: 18px;
    align-self: center;
    &--rotated {
      transform: rotate(45deg);
    }
  }
}
