.client {
  h2 {
    margin: 0;
  }
  &__flex-container {
    grid-gap: 24px;
    display: grid;
    justify-content: flex-start;
    grid-template-columns: repeat(auto-fit, minmax(206px, 240px));
    align-items: stretch;
  }
  &__card {
    border-radius: 16px !important;
    padding: 40px 20px;
    display: grid;
    gap: 25px;
    max-width: 250px;
    grid-template-rows: auto auto 40px;
    .title {
      font-size: 18px;
      font-weight: 700;
    }
    ul {
      margin: 0;
    }
    li {
      font-size: 14px;
      color: rgba(65, 64, 70, 0.6);
    }
  }
  &-container {
    display: flex;
    flex-direction: column;
    background-color: $colorBlanco;
  }

  &__back-container {
    padding: 24px 0 0 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  &__action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__project-container {
    display: flex;
    justify-content: center;
    justify-self: center;
  }
  &__card-dashed {
    background-color: $colorBlanco;
    border-radius: 10px;
    border: 1px dashed #d6d6d6;
    padding: 60px 100px;
    justify-content: center;
    display: grid;
    gap: 8px;
    text-align: center;
    .material-symbols-outlined {
      color: #ffffff;
    }
  }
  &__image-container {
    display: flex;
    justify-content: center;
    position: relative;
    &--start {
      justify-content: flex-start;
    }
  }
}
.circle {
  position: absolute;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background-color: rgba(11, 181, 231, 0.2);
  left: 50%;
}
.client-details {
  &__info-container {
    padding: 18px 36px;
    display: grid;
    gap: 16px;
  }

  &__grid-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
  }
  &__client-data {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &__flex-columns {
    display: flex;
  }
}
