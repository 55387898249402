.pagination {
  margin: 15px 0;
  nav {
    display: block;
  }
  svg {
    margin: 0px;
  }

  &__elements {
    align-items: center;
    float: right;
    display: flex;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;

    li {
      padding: 0 $baseSpace/2 - 4.5px;
      cursor: pointer;

      border-radius: 999999px;
      transition: background-color 0.3s;
      &:hover {
        background-color: rgba($buttonColor, 0.3);
      }
    }
  }

  &__next {
    color: rgba($buttonColor, 0.2);
    text-decoration: none;
    cursor: pointer;
    display: flex;
    margin: 0 4px;
    padding: 0px;
    align-items: center;
    justify-content: center;
  }

  li > span.active {
    color: $buttonColor;
  }
}
