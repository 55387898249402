.search-input {
  position: relative;
  align-self: center;
  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    color: $colorBase;
  }
  input {
    max-height: 36px;
    border-radius: 100px;
    border: 1px solid #9bc3d3;
    background-color: #f6fafc;
    padding: 8px 15px;
  }
}
.survey-search {
  height: 36px;
  input {
    border-radius: 100px 0 0 100px;
    border-right-width: 0;
  }
  .MuiIconButton-root {
    width: 37px !important;
    height: 36px !important;
    border-radius: 0 100px 100px 0;
    border: 1px solid #9bc3d3;
    background-color: #f6fafc;
    border-left-width: 0;
    &:hover {
      background-color: rgba(11, 181, 231, 0.1);
    }
  }
}
