.info-session {
  padding: 24px;

  &__item {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    display: flex;
    strong {
      width: 120px;
      display: inline-block;
    }
  }
}
.info-participants {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  &__personal,
  &__notes,
  &__poll {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0 24px;
  }

  &__personal {
    .button.disabled {
      z-index: inherit !important;
    }
    .info-participants__personal--title {
      display: flex;
      justify-content: space-between;
    }
  }
}
.cancel-session {
  background-color: white;
  padding: 24px;
  border-radius: 4px;
  outline: none;
  &.notificacion {
    padding: 60px 24px;
    h3 {
      margin-bottom: 40px;
    }
  }
}

.notes {
  &__item {
    margin-bottom: 1rem;
  }
  &__text {
    display: flex;
    align-items: center;
    svg,
    span {
      cursor: pointer;
      color: $colorBase;
    }
    svg {
      margin-right: 7px;
    }
  }
}

.assign-mentor__modal {
  padding: 30px;
}

.listItem:hover {
  background-color: #d6d6d6;
}

.sessionDetails:hover {
  cursor: pointer;
  transform: scale(1.3);
}

//session form
.session {
  display: grid;
  gap: 16px;
  &__container {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
  .button-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: center;
    gap: 32px;
    max-width: 600px;
    justify-self: center;
  }
  .title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 120px;
  }
}
