$yellow: #fac224;
$blue: #6ea6ff;
$gray: #979797;
$green: #8fb528;
.status {
  &-container {
    display: grid;
    gap: 8px;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: flex-start;
    &--error {
      color: red;
    }
  }

  &__indicator {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: red;
  }
  &__indicator--green {
    background-color: $green;
  }
  &__indicator--yellow {
    background-color: $yellow;
  }
  &__indicator--blue {
    background-color: $blue;
  }
  &__indicator--gray {
    background-color: $gray;
  }
  &__indicator--red {
    background-color: red;
  }
}
