.layout-private {
  &__container {
    width: 100%;
    min-height: 100vh;
  }

  &__content {
    background-color: $backgroundColor;
    min-height: calc(100% - 88.5px);
  }
}

.content-panel {
  display: flex;
  gap: 24px;
  flex-direction: column;
}
.flex-row {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.MuiGrid-spacing-xs-3 {
  margin: 0% !important;
}
