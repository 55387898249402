.component-header {
  text-align: end;
  border-bottom: 1px solid #e5e5e5;
  &__container {
    cursor: pointer;
    display: inline-flex;
    padding-top: calc($baseSpace/2);
    align-items: center;
    padding: 24px;

    .titles {
      text-align: right;
      margin-right: calc($baseSpace/2);
    }

    .perfil__inicial {
      border-radius: 9999px;
      height: 2.5rem;
      width: 2.5rem;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: bold;
      display: inline-flex;
      margin: 0px 0.5rem;
      background-color: $colorBase;
    }
  }
}
