.container-info-mentee {
  .resumen-mentee {
    row-gap: 24px;
    padding: 36px 12px;
    .title-resumen {
      font-size: 18px;
    }
    .chip-resumen {
      background-color: #0bb5e7;
      color: #ffffff;
      text-transform: capitalize;
      height: 22px;
    }
    .label {
      font-size: 16px;
    }
    .data-label {
      font-size: 14px;
      color: #52575c;
    }
    .MuiFormControlLabel-root {
      padding-left: 10px;
      margin-right: 0px;
      .MuiTypography-body1 {
        font-size: 16px;
        font-family: 'PT Sans', sans-serif;
      }
    }
  }
  .textField-form {
    .MuiOutlinedInput-root {
      border-radius: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #f5fafd;
      height: 50px;
      & fieldset {
        border-color: #deedf2;
        border-bottom-color: #257fa4;
        border-bottom-width: 2px;
        margin-top: 4px;
      }
      &:hover {
        &fieldset {
          border-color: #deedf2;
          border-bottom-color: #257fa4;
          border-bottom-width: 2px;
        }
      }
      & input {
        padding: 20px 16px 2px;
        color: #25282b80 !important;
      }

      & legend {
        display: none;
      }
      .MuiSelect-outlined.MuiSelect-outlined {
        background-color: transparent;
        padding: 13px 22px 2px;
        color: #25282b80;
      }
    }
    .MuiSelect-icon {
      color: #0bb5e7;
    }
    .Mui-focused {
      & fieldset {
        border-color: #deedf2;
        border-bottom-color: #257fa4;
        border-bottom-width: 2px;
      }
      & input {
        padding: 20px 16px 2px;
        color: #25282b80;
      }
      .MuiInputLabel-root {
        margin-top: 10px;
        color: #25282b80;
      }
      .MuiSelect-select {
        &:focus {
          background-color: transparent;
          padding: 13px 22px 2px;
          color: #25282b80;
        }
      }
    }

    .MuiInputLabel-root {
      color: #767676;
      box-sizing: border-box;
    }
    .MuiInputLabel-outlined {
      .Mui-focused {
        color: #767676;
        margin-top: 10px;
      }
    }
    .MuiSelect-icon {
      color: #0bb5e7;
    }
    .select-multiple {
      .MuiSelect-outlined.MuiSelect-outlined {
        padding: 13px 4px;
        &:focus {
          padding: 13px 4px;
        }
        .chips-container {
          gap: 4px;
          display: flex;
          flex-wrap: wrap;
          .chips {
            margin: 2;
            background-color: #0bb5e7;
            color: #e1f5fe;
            border-radius: 4px;
            border-color: #f0f0f0;
            width: 166px;
            .delete-button {
              margin-left: 8px;
              color: #e1f5fe;
              &:hover {
                background-color: #e1f5fe;
              }
            }
            // .MuiChip-label {
            //   padding-right: 0px;
            // }
          }
          .more-options {
            width: fit-content !important;
            // .MuiChip-label {
            //   padding-right: 12px;
            // }
          }
        }
      }
    }
    .MuiSelect-root.MuiSelect-multiple {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 10px; // Espaciado para las opciones seleccionadas
      color: #25282b80;

      .MuiSelect-chip {
        background-color: #deedf2;
        margin: 4px;
        padding: 4px 8px;
        border-radius: 16px;
        font-size: 14px;
      }
    }

    .MuiSelect-select.MuiSelect-selectMultiple {
      padding: 8px 10px;
      color: #25282b80;
    }

    // Responsivo
    @media (max-width: 600px) {
      .MuiSelect-root.MuiSelect-multiple {
        max-height: 120px;
        overflow-y: auto;
      }
    }
  }
}
.selectMenu {
  background-color: #ffffff; // Fondo blanco
  border-radius: 8px; // Bordes redondeados
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); // Sombra suave
  max-height: 200px; // Altura máxima para el menú
  overflow-y: auto; // Añadir desplazamiento si el contenido excede la altura máxima
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: #0bb5e71a;
  }
}

.emprendimiento-container {
  box-shadow: 0px 12px 26px 0px #101e730f;

  .textField-form {
    .MuiOutlinedInput-root {
      border-radius: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #f5fafd;
      height: 50px;
      & fieldset {
        border-color: #deedf2;
        border-bottom-color: #257fa4;
        border-bottom-width: 2px;
        margin-top: 4px;
      }
      &:hover {
        &fieldset {
          border-color: #deedf2;
          border-bottom-color: #257fa4;
          border-bottom-width: 2px;
        }
      }
      & input {
        padding: 20px 16px 2px;
        color: #25282b80 !important;
      }

      & legend {
        display: none;
      }
      .MuiSelect-outlined.MuiSelect-outlined {
        background-color: transparent;
        padding: 13px 22px 2px;
        color: #25282b80;
      }
    }
    .MuiSelect-icon {
      color: #0bb5e7;
    }
    .Mui-focused {
      & fieldset {
        border-color: #deedf2;
        border-bottom-color: #257fa4;
        border-bottom-width: 2px;
      }
      & input {
        padding: 20px 16px 2px;
        color: #25282b80;
      }
      .MuiInputLabel-root {
        margin-top: 10px;
        color: #25282b80;
      }
      .MuiSelect-select {
        &:focus {
          background-color: transparent;
          padding: 13px 22px 2px;
          color: #25282b80;
        }
      }
    }

    .MuiInputLabel-root {
      color: #767676;
    }
    .MuiInputLabel-outlined {
      .Mui-focused {
        color: #767676;
        margin-top: 10px;
      }
    }
    .MuiSelect-icon {
      color: #0bb5e7;
    }
    .select-multiple {
      .MuiSelect-outlined.MuiSelect-outlined {
        padding: 13px 4px;
        &:focus {
          padding: 13px 4px;
        }
        .chips-container {
          gap: 4px;
          display: flex;
          flex-wrap: wrap;
          .chips {
            margin: 2;
            background-color: #0bb5e7;
            color: #e1f5fe;
            border-radius: 4px;
            border-color: #f0f0f0;
            width: 166px;
            .delete-button {
              margin-left: 8px;
              color: #e1f5fe;
              &:hover {
                background-color: #e1f5fe;
              }
            }
            // .MuiChip-label {
            //   padding-right: 0px;
            // }
          }
          .more-options {
            width: fit-content !important;
            // .MuiChip-label {
            //   padding-right: 12px;
            // }
          }
        }
      }
    }
    .MuiSelect-root.MuiSelect-multiple {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 10px; // Espaciado para las opciones seleccionadas
      color: #25282b80;

      .MuiSelect-chip {
        background-color: #deedf2;
        margin: 4px;
        padding: 4px 8px;
        border-radius: 16px;
        font-size: 14px;
      }
    }

    .MuiSelect-select.MuiSelect-selectMultiple {
      padding: 8px 10px;
      color: #25282b80;
    }

    // Responsivo
    @media (max-width: 600px) {
      .MuiSelect-root.MuiSelect-multiple {
        max-height: 120px;
        overflow-y: auto;
      }
    }
  }
  .label {
    font-size: 16px;
  }
  .data-label {
    font-size: 14px;
    color: #52575c;
  }
  .selectMenu {
    background-color: #ffffff; // Fondo blanco
    border-radius: 8px; // Bordes redondeados
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); // Sombra suave
    max-height: 200px; // Altura máxima para el menú
    overflow-y: auto; // Añadir desplazamiento si el contenido excede la altura máxima
    .MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {
      background-color: #0bb5e71a;
    }
  }
}

.textArea-form {
  .MuiOutlinedInput-root {
    border-radius: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #f5fafd;
    height: auto; /* Permitir altura dinámica */
    min-height: 150px; /* Asegura que sea más grande */
    & fieldset {
      border-color: #deedf2;
      border-bottom-color: #257fa4;
      border-bottom-width: 2px;
      margin-top: 4px;
    }
    &:hover {
      & fieldset {
        border-color: #deedf2;
        border-bottom-color: #257fa4;
        border-bottom-width: 2px;
      }
    }
    & input, & textarea {
      padding: 20px 16px 8px; /* Ajustar padding */
      color: #25282b80 !important;
      resize: none; /* Deshabilitar el cambio de tamaño manual */
    }

    & legend {
      display: none;
    }
    .MuiSelect-outlined.MuiSelect-outlined {
      background-color: transparent;
      padding: 13px 22px 2px;
      color: #25282b80;
    }
  }

  .MuiSelect-icon {
    color: #0bb5e7;
  }

  .Mui-focused {
    & fieldset {
      border-color: #deedf2;
      border-bottom-color: #257fa4;
      border-bottom-width: 2px;
    }
    & input, & textarea {
      padding: 20px 16px 8px;
      color: #25282b80;
    }
    .MuiInputLabel-root {
      margin-top: 10px;
      color: #25282b80;
    }
    .MuiSelect-select {
      &:focus {
        background-color: transparent;
        padding: 13px 22px 2px;
        color: #25282b80;
      }
    }
  }
}
