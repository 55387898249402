.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(236, 240, 239, 0.5);
  .confirmation-modal {
    background-color: white;
    border-radius: 8px;
    padding: 12px;
    .button-container {
      display: flex;
      justify-content: space-evenly;
      margin-top: 10px;
    }
  }
}
.user-container {
  padding: 24px;

  &__creation,
  &__edit {
    padding: 34px;
    .content {
      min-height: calc(100vh - 222px);
    }
    .title {
      position: relative;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background-color: white;
      padding: 20px;
      display: flex;
      align-items: center;
      font-weight: bold;
      svg {
        color: $colorBase;
        margin-right: 10px;
      }
      .delete-button {
        position: absolute;
        display: flex;
        justify-content: center;
        bottom: -20px;
        right: 15px;
        background-color: $colorBase;
        border-radius: 100%;
        svg {
          margin: 0px !important;
          color: white;
        }
        &.none-mentor {
          right: -25px;
        }
      }
    }
    .activeUser {
      padding: 0px 15px;
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .statusSelector {
        border: 1px solid #deedf2;
        border-bottom: #257fa4;
        border-radius: 8px;
        .MuiSelect-select.MuiSelect-select {
          color: rgba(0, 0, 0, 0.7);
          padding-left: 14px;
        }
        .MuiSelect-icon {
          color: #0bb5e7;
        }
      }
      label {
        color: white;
        margin: 0px;
      }
      .MuiSwitch-colorSecondary.Mui-checked {
        color: $colorBase !important;
      }
      .MuiSwitch-track {
        background-color: $colorBase !important;
      }
    }

    .configuraciones-info {
      padding: 24px;
      .input-swith {
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
    .personal-info,
    .role-info {
      padding: 0 24px;

      &__role {
        cursor: pointer;
      }
    }
    .personal-info {
      .pilares__category-item {
        text-align: right;
        margin-top: 24px;
      }
      .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: $colorBase;
      }
      .MuiSwitch-colorSecondary.Mui-checked {
        color: $colorBase;
      }
      .switch-label {
        margin-top: 0;
      }
    }
    .pillars {
      .pilares__category-item {
        display: inline-block;
      }
      .pilares__preRegistry {
        margin-bottom: 20px;
        .pilares__category-pre {
          display: block;
          margin-bottom: 5px;
        }
      }
    }

    .horarios {
      background-color: white;
      display: grid;
      grid-template-columns: auto auto;
      .login-pilares__description {
        font-weight: 400;
        font-size: 1.125rem;
      }

      .horarios__container {
        padding-bottom: 12px;

        .container__item {
          padding-bottom: 20px;
          .item-title-container {
            display: flex;
            justify-content: space-between;
            .horarios__item-title {
              font-weight: bold;
              margin-bottom: 12px;
            }
            .schedules {
              color: #0bb5e7;
              cursor: pointer;
              padding-right: 15px;
              display: flex;
              font-weight: 700;
              font-size: 16px;
            }
          }
          .horarios__item {
            background-color: #ececec;
            display: inline-block;
            padding: 4px 12px;
            border-radius: 1rem;
            margin-right: 8px;
            cursor: pointer;
            font-size: 12px;
            margin-bottom: 8px;

            &.active {
              background-color: $colorBase;
              color: white;
            }
            .item-title__icon {
              font-size: 1rem;
            }
          }
        }
      }
      .horarios__selected-container {
        display: grid;
        grid-template-columns: auto auto auto;
        margin-bottom: 15px;
        max-height: 300px;
        overflow: auto;

        .selected__item-title {
          font-weight: bold;
          margin-bottom: 12px;
          font-size: 1rem;
        }
        .selected__date {
          font-weight: bold;
          font-size: 0.875rem;
        }
        .selected__hour {
          font-size: 0.875rem;
          margin-bottom: 8px;
          background-color: #ebebeb;
          border-radius: 8px;
          padding: 5px;
          width: max-content;
          display: flex;
          margin-right: 1.6rem;

          .selected__remove {
            margin-left: 12px;
            background-color: white;
            border-radius: 9999px;
            text-align: center;
            width: 1rem;
            height: 1rem;
            font-size: 0.75rem;
            color: $colorBase;
            cursor: pointer;
            line-height: 1;
            vertical-align: middle;
            width: max-content;
            .remove__icon {
              width: 1rem;
              height: 1rem;
            }
          }
          &:hover {
            .selected__remove {
              display: inline-block;
            }
          }
        }
        .MuiFormControl-root {
          background-color: #deedf2;
          border-radius: 4px;
          width: 33.333333%;
          border-top: 1px solid #cedee7;
          border-left: 1px solid #cedee7;
          border-right: 1px solid #cedee7;
          label {
            margin: 0;
            padding-left: 0.75rem;
          }
          .MuiInputBase-input {
            padding-left: 12px;
            color: #111111;
            padding-top: 8px;
          }
          .MuiInput-underline:after {
            border-color: #257fa4;
          }
          .MuiInput-underline:before {
            border-color: #257fa4;
          }
        }
      }
    }

    .submit {
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 20px;
    }
    .list-reports {
      svg {
        color: $colorBase;
      }
    }
  }
  &__edit {
    padding: 0;
    .empty {
      color: rgba(0, 0, 0, 0.5);
      font-style: italic;
      margin-left: 24px;
    }
    .submit {
      border: 0 none;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .MuiTabs-indicator {
      background-color: $colorBase;
      height: 4px;
    }
  }
  .material-symbols-outlined {
    color: rgba(11, 181, 231, 0.7);
  }
  .div-container-status-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: fit-content;
    .point-status {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    .green {
      background-color: #8fb528;
    }
    .gray {
      background-color: #979797;
    }
    .with-border {
      background-color: #ffffff;
      border: 1px solid #979797;
    }
  }
  .reloadDate {
    color: #5b5b5b;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-left: 10px;
    margin-top: 24px;
  }
}
@media only screen and (max-width: 768px) {
  .user-container {
    &__creation,
    &__edit {
      .horarios {
        grid-template-columns: auto !important;
      }
    }
  }
}
