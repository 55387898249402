.input {
  border: 1px solid #deedf2;
  border-bottom: 1px solid rgba(37, 127, 164, 1);
  border-radius: 3px 3px 0px 0px;
  color: #8c8c8c;
}
.title {
  font-weight: 700;
  font-size: 16px;
}
p {
  margin: 0px;
}
.step-navigation {
  display: grid;
  grid-template-columns: 300px 300px;
  justify-content: center;
  justify-items: center;
}
.client__card ul {
  margin-top: 10px;
}
.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 100, 'GRAD' -25, 'opsz' 24;
}


.summaryInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}
.containerworkshopcontent {
  display: grid;
  gap: 20px;
  padding: 32px 5rem 0px 15rem;
  max-width: 720px;
}
.workshop-table-container .rt-td {
  color: #52575c !important;
}

//workshop table
.workshop {
  &__cell {
    display: flex;
    gap: 16px;
    .text {
      text-overflow: ellipsis;
      max-width: 200px;
      -webkit-line-clamp: 1;
      white-space: nowrap;
      overflow: hidden;
    }
    .link {
      color: inherit;
      text-decoration: none;
    }
  }
  &__action {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 120px));
  }
}
