$black: #000000;

.wizard {
  display: grid;
  min-height: calc(100vh - 136px);
  align-content: start;

  .wizard-padding {
    padding: 16px 30px;
  }

  &__toggle-container {
    margin: -16px -30px !important;
    background: #055169;
    color: #fff;
    background: #055169;
    padding: 20px 24px;
    &--disabled {
      span {
        color: #bdbdbd !important;
      }
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    color: $black;
  }

  &__title-section {
    font-weight: 700;
    font-size: 20px;
    color: $black;
  }

  &__title-container {
    border-bottom: 1px solid $colorPrimario;
    display: flex;
    justify-content: space-between;
  }

  &__content {
    display: grid;
    grid-template-columns: auto 1fr;
    min-height: 100vh;
    &--col {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__steps-container {
    border-right: 1px solid $colorPrimario;
    display: grid;
    row-gap: 24px;
    align-content: start;
    align-self: stretch;
  }

  &__form {
    display: grid;
    padding: 20px 64px;
    padding-right: 100px;
    min-width: 500px;
    max-width: 600px;
    row-gap: 32px;
    justify-content: flex-start;
    align-content: start;
    &--full {
      justify-content: inherit;
    }
    &--center {
      padding: 20;
      justify-content: center;
    }

    label {
      margin: 0;
    }

    .input__container {
      margin: 0;
    }
  }

  &__action {
    display: grid;
    grid-template-columns: auto auto;
    gap: 12px;
  }
  &__lower-action {
    display: grid;
    justify-content: center;
    grid-template-columns: 250px 250px;
    gap: 24px;
    padding: 32px;
    border-top: 1px solid $colorPrimario;
  }

  &__grid-section {
    display: grid;
    gap: 24px;
  }

  &__preview-container {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    position: sticky;
    top: 20px;
  }
}

@media (max-width: 1200px) {
  .wizard {
    &__form {
      padding: 20px 30px;
      padding-right: 30px;
    }
  }
}
