.box-participants {
  display: flex;
  align-items: center;

  &__avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: $colorBase;
    color: white;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
    cursor: pointer;
  }
  span {
    font-weight: bold;
    cursor: pointer;
  }
}
