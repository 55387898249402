.close-container {
  text-align: right;
  padding: 0px;

  .close-benefit {
    background-color: #0bb5e7;
    padding: 8px !important;
    color: white;
    position: absolute;
  }
  .close-benefit:hover {
    color: black;
    background-color: #0bb4e774;
  }
}
.edit-pen {
  color: #0bb5e7;
  font-weight: bold;
  font-size: 1.3rem !important;
}
.benefit-title {
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 10px;
}
.benefit-switch {
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #0bb5e7;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #0bb5e7;
  }
}
.benefit-sub-label {
  font-size: 13px;
}
// .button {
//   padding: 6px 10px !important;
// }
.preview-grid {
  height: 290px;
  background-color: rgb(250, 249, 249);

  .preview-image {
    height: 90%;
    width: 90%;
  }
}
.benefit-error {
  font-size: 0.875rem;
  color: #e53e3e;
}

.editorSpace {
  .rdw-editor-main {
    border: 1px solid black;
    height: 300px;
    overflow-y: scroll;
  }
  .rdw-editor-toolbar {
    border: 1px solid black;
    margin-bottom: 0;
    border-bottom: 0px;
    border-radius: 0;
  }

  .rdw-link-modal {
    height: 305px !important;
  }
}
