/*------------------------------------*\
    $LOADER
\*------------------------------------*/

.loader__container {
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  .loader__content {
    width: 320px;
    text-align: center;
    background-color: #fff;
    padding: 20px 20px 0px;
    border-radius: 8px;

    .loader__spin {
      width: 60px;
      height: 60px;
      border: 5px solid rgba(67, 117, 158, 0.1);
      border-top-color: $colorBase;
      animation: spin 1s ease-in-out infinite;
      -webkit-animation: spin 1s ease-in-out infinite;
      display: inline-block;
      margin-bottom: 10px;
      border-radius: 50%;
    }
    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }

    p {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
