/*------------------------------------*\
    $ALERT
\*------------------------------------*/

.alert {
  display: flex;
  align-items: center;
  color: #000000;

  p {
    font-size: 0.875rem;
    font-family: 'PT Sans', sans-serif;
  }

  &.error {
    border-left: 5px solid #dc0025;
    background-color: #ffeeef;

    svg {
      color: #dc0025;
      width: 1.25rem;
      margin: auto 0.75rem;
    }
  }
  &.warning {
    border-left: 5px solid #f3ba1e;
    background-color: #fff7dd;

    svg {
      color: #f3ba1e;
      width: 1.25rem;
      margin: auto 0.75rem;
    }
  }

  &.info {
    border-left: 5px solid $colorBase;
    background-color: #deedf2;

    svg {
      color: $colorBase;
      width: 1.25rem;
      margin: auto 0.75rem;
    }
  }

  &.success {
    border-left: 5px solid #00993f;
    background-color: #d7fbe2;

    svg {
      color: #00993f;
      width: 1.25rem;
      margin: auto 0.75rem;
    }
  }
}
