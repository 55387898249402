.avatar {
  display: flex;
  height: 65px;
  width: 65px;
  overflow: hidden;
  border-radius: 50%;
  background-color: $colorPrimario;
  img {
    object-fit: contain;
    height: auto;
    max-width: 100%;
  }
  &--small {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    background-color: $colorPrimario;
    padding: 8px;
    &:after {
      content: '';
      background-color: $colorPrimario;
      height: 45px !important;
      width: 45px !important;
      border-radius: 50%;
    }
  }
  &--extra-small {
    padding: 5px;
    height: 28px;
    width: 28px;
    min-width: 28px;
    border-radius: 50%;
    background-color: $colorPrimario;
    &:after {
      content: '';
      background-color: $colorPrimario;
      height: 45px !important;
      width: 45px !important;
      border-radius: 50%;
    }
  }
  &:after {
    content: '';
    background-color: $colorPrimario;
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }
}
