.container-future-sessions {
  box-shadow: 0px 12px 26px 0px #101e730f;
  .textField-form {
    .MuiOutlinedInput-root {
      border-radius: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #f5fafd;
      height: 50px;
      & fieldset {
        border-color: #deedf2;
        border-bottom-color: #257fa4;
        border-bottom-width: 2px;
        margin-top: 4px;
      }
      &:hover {
        &fieldset {
          border-color: #deedf2;
          border-bottom-color: #257fa4;
          border-bottom-width: 2px;
        }
      }
      & input {
        padding: 20px 16px 2px;
        color: #25282b80 !important;
      }

      & legend {
        display: none;
      }
      .MuiSelect-outlined.MuiSelect-outlined {
        background-color: transparent;
        padding: 13px 22px 2px;
        color: #25282b80;
      }
    }
    .MuiSelect-icon {
      color: #0bb5e7;
    }
    .Mui-focused {
      & fieldset {
        border-color: #deedf2;
        border-bottom-color: #257fa4;
        border-bottom-width: 2px;
      }
      & input {
        padding: 20px 16px 2px;
        color: #25282b80;
      }
      .MuiInputLabel-root {
        margin-top: 10px;
        color: #25282b80;
      }
      .MuiSelect-select {
        &:focus {
          background-color: transparent;
          padding: 13px 22px 2px;
          color: #25282b80;
        }
      }
    }

    .MuiInputLabel-root {
      color: #767676;
      box-sizing: border-box;
    }
    .MuiInputLabel-outlined {
      .Mui-focused {
        color: #767676;
        margin-top: 10px;
      }
    }
    .MuiSelect-icon {
      color: #0bb5e7;
    }
    .select-multiple {
      .MuiSelect-outlined.MuiSelect-outlined {
        padding: 13px 4px;
        &:focus {
          padding: 13px 4px;
        }
        .chips-container {
          gap: 4px;
          display: flex;
          flex-wrap: wrap;
          .chips {
            margin: 2;
            background-color: #0bb5e7;
            color: #e1f5fe;
            border-radius: 4px;
            border-color: #f0f0f0;
            width: 166px;
            .delete-button {
              margin-left: 8px;
              color: #e1f5fe;
              &:hover {
                background-color: #e1f5fe;
              }
            }
            // .MuiChip-label {
            //   padding-right: 0px;
            // }
          }
          .more-options {
            width: fit-content !important;
            // .MuiChip-label {
            //   padding-right: 12px;
            // }
          }
        }
      }
    }
    .MuiSelect-root.MuiSelect-multiple {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 10px; // Espaciado para las opciones seleccionadas
      color: #25282b80;

      .MuiSelect-chip {
        background-color: #deedf2;
        margin: 4px;
        padding: 4px 8px;
        border-radius: 16px;
        font-size: 14px;
      }
    }

    .MuiSelect-select.MuiSelect-selectMultiple {
      padding: 8px 10px;
      color: #25282b80;
    }

    // Responsivo
    @media (max-width: 600px) {
      .MuiSelect-root.MuiSelect-multiple {
        max-height: 120px;
        overflow-y: auto;
      }
    }
  }
  .textFieldArea-form {
    .MuiOutlinedInput-root {
      border-radius: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #f5fafd;

      & fieldset {
        border-color: #deedf2;
        border-bottom-color: #257fa4;
        border-bottom-width: 2px;
        margin-top: 4px;
      }
      &:hover {
        &fieldset {
          border-color: #deedf2;
          border-bottom-color: #257fa4;
          border-bottom-width: 2px;
        }
      }
      & input {
        padding: 20px 16px 2px;
        color: #25282b80 !important;
      }

      & legend {
        display: none;
      }
      .MuiSelect-outlined.MuiSelect-outlined {
        background-color: transparent;
        padding: 13px 22px 2px;
        color: #25282b80;
      }
    }
    .MuiSelect-icon {
      color: #0bb5e7;
    }
    .Mui-focused {
      & fieldset {
        border-color: #deedf2;
        border-bottom-color: #257fa4;
        border-bottom-width: 2px;
      }
      & input {
        padding: 20px 16px 2px;
        color: #25282b80;
      }
      .MuiInputLabel-root {
        margin-top: 10px;
        color: #25282b80;
      }
      .MuiSelect-select {
        &:focus {
          background-color: transparent;
          padding: 13px 22px 2px;
          color: #25282b80;
        }
      }
    }

    .MuiInputLabel-root {
      color: #767676;
      box-sizing: border-box;
    }
    .MuiInputLabel-outlined {
      .Mui-focused {
        color: #767676;
        margin-top: 10px;
      }
    }
    .MuiSelect-icon {
      color: #0bb5e7;
    }
    .select-multiple {
      .MuiSelect-outlined.MuiSelect-outlined {
        padding: 13px 4px;
        &:focus {
          padding: 13px 4px;
        }
        .chips-container {
          gap: 4px;
          display: flex;
          flex-wrap: wrap;
          .chips {
            margin: 2;
            background-color: #0bb5e7;
            color: #e1f5fe;
            border-radius: 4px;
            border-color: #f0f0f0;
            width: 166px;
            .delete-button {
              margin-left: 8px;
              color: #e1f5fe;
              &:hover {
                background-color: #e1f5fe;
              }
            }
            // .MuiChip-label {
            //   padding-right: 0px;
            // }
          }
          .more-options {
            width: fit-content !important;
            // .MuiChip-label {
            //   padding-right: 12px;
            // }
          }
        }
      }
    }
    .MuiSelect-root.MuiSelect-multiple {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 10px; // Espaciado para las opciones seleccionadas
      color: #25282b80;

      .MuiSelect-chip {
        background-color: #deedf2;
        margin: 4px;
        padding: 4px 8px;
        border-radius: 16px;
        font-size: 14px;
      }
    }

    .MuiSelect-select.MuiSelect-selectMultiple {
      padding: 8px 10px;
      color: #25282b80;
    }

    // Responsivo
    @media (max-width: 600px) {
      .MuiSelect-root.MuiSelect-multiple {
        max-height: 120px;
        overflow-y: auto;
      }
    }
  }
  .textFieldPicker-form {
    .MuiInput-underline {
      border-radius: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #f5fafd;
      height: 51px;
      border: 1px solid #deedf2;
      &::before {
        border-color: #deedf2;
        border-bottom-color: #257fa4 !important;
        border-bottom-width: 2px !important;
        margin-top: 4px;
      }
      &::after {
        border-bottom-color: #257fa4 !important;
        border-bottom-width: 2px !important;
      }
      & input {
        padding: 20px 16px;
        color: #25282b80 !important;
      }
    }
    .Mui-focused {
      height: 52px;
      border-width: 2px;
    }
  }
  .list-invitado {
    .item-list {
      padding: 16px 0px;
      display: flex;
      justify-content: space-between;
      gap: 32px;
      .icon {
        color: #0bb5e7;
        cursor: pointer;
      }
    }
  }
  .container-btn-horarios {
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: stretch;
    .MuiButton-root {
      color: #0bb5e7;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .future-sessions {
    h4 {
      margin-top: 4px;
      margin-bottom: 10px;
    }
    .future-sessions-column {
      padding-top: 20px !important;
      .date-grid {
        padding-right: 20px;
      }
      .hour1-grid {
        label + .MuiInput-formControl {
          margin-top: 10px !important;
        }
      }
    }
    .future-sessions-subtitle {
      padding: 6px 0px 3px 24px;
      background-color: #f5f5f5;
      border: 1px solid #e9e9e9;
      .subtitle {
        margin: 4px 0px 4px 8px;
      }
    }
    .empty-box {
      background: $colorBlanco;
      border: 1px solid #e9e9e9;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80%;
      scroll-behavior: auto;
    }
    .shedules-box {
      padding-top: 20px;
      background: $colorBlanco;
      border: 1px solid #e9e9e9;
      justify-content: center;
      align-items: center;
      height: 360px;
      overflow-y: scroll !important;
      .error-grid {
        margin-bottom: 15px;
      }
    }
    .description-box {
      padding-bottom: 30px;
    }
    .name-grid {
      padding-left: 15px;
      margin-right: 10px;
      padding-top: 15px;
      justify-content: center;
      align-items: center;
    }

    .date-grid {
      padding-right: 20px;
    }
    .hour-grid {
      padding-left: 0px;
      padding-right: 105x;
    }
  }

  //sessions client
  .meeting-summary {
    &__container {
      display: grid;
      grid-gap: 45px;
      padding: 12px 24px;
      border: 1px solid #e9e9e9;
      border-radius: 4px;
      min-width: 300px;
      align-content: start;
      max-height: 600px;
      overflow-y: scroll;
      &--empty {
        align-content: stretch;
      }
    }
    .empty-box {
      justify-self: center;
      align-self: center;
    }
  }
}
