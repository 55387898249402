.layout-public__container {
  width: 100%;
  height: 100vh;
  background: url('https://picsum.photos/1366/768') no-repeat right center;
  background-size: calc(80% - 0px) calc(100% - 0px);
}

@media (max-width: 1024px) {
  .layout-public__container {
    background-size: cover;
  }
}
