.container-historial {
  padding: 32px 24px;
  min-height: 805px;
  .title-history {
    font-size: 24px;
    color: #1b2c45;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .category-button {
    padding: 6px 12px;
    border: 1px solid #979797;
    background-color: white;
    color: #979797;
    border-radius: 10px;
    height: 36px;
    transition: background-color 0.3s ease;
    text-transform: none;
    &:hover {
      background-color: white;
      color: #0bb5e7;
      border: 1px solid #0bb5e7;
    }
  }
  .active {
    background-color: #0bb5e7;
    color: white;
    border: 1px solid #0bb5e7;
  }
  .title-table {
    font-size: 18px;
    font-weight: 700;
  }
}
