.component-menu {
  box-shadow: 4px 0px 16px rgba(16, 30, 115, 0.08);
  z-index: 1;
  &__container {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      align-items: flex-start;
      display: flex;
      font-size: 14px;
      li {
        span {
          color: #52575c;
        }
        .material-symbols-outlined {
          color: #dbdde0;
        }
        svg {
          color: #dbdde0;
        }
        .icon {
          color: #dbdde0;
        }
        &.active {
          span {
            color: $colorBase;
          }

          svg {
            color: $colorBase;
          }
        }
      }
    }
    a {
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      .active {
        visibility: visible;
        color: $colorBase;
        font-weight: bold;
      }
      .hide {
        visibility: hidden;
      }
    }
    svg {
      vertical-align: top;
    }

    .converge-image {
      max-width: 150px;
      min-width: 50px;
      margin: 0 auto;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 600px) {
  .menu-text {
    display: none;
  }

  .component-menu {
    box-shadow: 4px 0px 16px rgba(16, 30, 115, 0.08);

    &__container {
      padding: 24px 8px;
      ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      a {
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .active {
          visibility: visible;
          color: $colorBase;
          font-weight: bold;
        }
        .hide {
          visibility: hidden;
        }
      }
      svg {
        margin-right: 0.5rem;
        vertical-align: top;
      }
    }
  }
}
